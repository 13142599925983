import { ICheckboxFilter } from "../CheckboxFilter";

export enum ConstrainedListFilterMust {
  MATCH_ALL = "MATCH_ALL",
  MATCH_ANY = "MATCH_ANY",
  MATCH_NONE = "MATCH_NONE",
}

export type ConstrainedListSearchFilterId =
  | "adopted-constraints-id"
  | "company-status-id"
  | "emerging-constraints-id"
  | "flum-id"
  | "planning-status-id"
  | "ownership-type-id"
  | "site-allocations-id"
  | "site-allocations-emerging-id"
  | "land-availability-assessment-id"
  | "zone-type-id"
  | "property-type-id"
  | "tenure-uk"
  | "agricultural-land-classification-id"
  | "additional-opportunities-id"
  | "property-state-id"
  | "regeneration-zones-adopted-id"
  | "regeneration-zones-emerging-id"
  | "us-wetlands-id"
  | "grey-belt-type-id"
  | "article-four-id";

export type ConstrainedListFilterMustDTO = keyof typeof ConstrainedListFilterMust;

export interface IConstrainedListSearchFilter {
  id: ConstrainedListSearchFilterId;
  values: ICheckboxFilter[];
  must: ConstrainedListFilterMust;
  type: "checkbox";
}

export type ConstrainedListSearchFilterDTO = Omit<IConstrainedListSearchFilter, "must"> & {
  must: ConstrainedListFilterMustDTO;
};
