import { AdditionalOpportunitiesFilter } from "./AdditionalOpportunitiesFilter";
import { AdoptedConstraintsFilter } from "./AdoptedConstraintsFilter";
import { AgriculturalLandClassificationFilter } from "./AgriculturalLandClassificationFilter/AgriculturalLandClassificationFilter";
import { CompanyStatusFilter } from "./CompanyStatusFilter";
import { EmergingConstraintsFilter } from "./EmergingConstraintsFilter";
import { LandAvailabilityAssessmentFilter } from "./LandAvailabilityAssessmentFilter";
import { LastSaleDateFilter } from "./LastSaleDateFilter";
import { LeaseEndDateFilter } from "./LeaseEndDateFilter";
import { LeaseStartDateFilter } from "./LeaseStartDateFilter";
import { LotAreaFilter } from "./LotAreaFilter";
import { OwnershipTypeFilter } from "./OwnershipTypeFilter";
import { PermittedUseFilter } from "./PermittedUseFilter";
import { PlanningApplicationsFilter } from "./PlanningApplicationsFilter";
import { ProprietorNameFilter } from "./ProprietorNameFilter";
import { PropertyStateFilter } from "./PropertyStateFilter";
import { PropertyTypeFilter } from "./PropertyTypeFilter";
import { RegenerationZonesAdoptedFilter } from "./RegenerationZonesAdoptedFilter";
import { RegenerationZonesEmergingFilter } from "./RegenerationZonesEmergingFilter";
import { SearchLocationFilter } from "./SearchLocationFilter";
import { SiteAllocationsAdoptedFilter } from "./SiteAllocationsAdoptedFilter";
import { TenureFilter } from "./TenureFilter";
import { YearBuiltFilter } from "./YearBuiltFilter";
import { ZoneTypeFilter } from "./ZoneTypeFilter";
import { FlumFilter } from "./FlumFilter";
import { UseClassNestedFilter } from "./UseClassNestedFilter";
import { WetlandsUSFilter } from "./WetlandsUSFilter";
import { ZoneCodesFilter } from "./ZoneCodesFilter";
import { DevelopedAreaFilter } from "./DevelopedAreaFilter";
import { OwnerPortfolioFilter } from "./OwnerPortfolioFilter";
import { TotalWetlandsCoverageUSFilter } from "./TotalWetlandsCoverageUSFilter";
import { AmenitiesFilter } from "./AmenitiesFilter";
import { SiteAllocationsEmergingFilter } from "./SiteAllocationsEmergingFilter";
import { ISearchFilter } from "react-migration/domains/search/typings/Filters";
import { GreyBeltTypeFilter } from "./GreyBeltTypeFilter";
import { Article4Filter } from "./Article4Filter";

export const filters: ISearchFilter[] = [
  // SearchLocationFilter comes first despite alphabetical order so that it renders first
  // in the default filters - simpler than adding some complex orderding logic to everything else
  SearchLocationFilter,
  AdditionalOpportunitiesFilter,
  AdoptedConstraintsFilter,
  Article4Filter,
  AmenitiesFilter,
  AgriculturalLandClassificationFilter,
  CompanyStatusFilter,
  DevelopedAreaFilter,
  EmergingConstraintsFilter,
  FlumFilter,
  LandAvailabilityAssessmentFilter,
  LastSaleDateFilter,
  LeaseEndDateFilter,
  LeaseStartDateFilter,
  LotAreaFilter,
  OwnerPortfolioFilter,
  OwnershipTypeFilter,
  GreyBeltTypeFilter,
  PermittedUseFilter,
  PlanningApplicationsFilter,
  ProprietorNameFilter,
  PropertyStateFilter,
  PropertyTypeFilter,
  RegenerationZonesAdoptedFilter,
  RegenerationZonesEmergingFilter,
  SiteAllocationsAdoptedFilter,
  SiteAllocationsEmergingFilter,
  TenureFilter,
  TotalWetlandsCoverageUSFilter,
  YearBuiltFilter,
  ZoneTypeFilter,
  UseClassNestedFilter,
  WetlandsUSFilter,
  ZoneCodesFilter,
];
