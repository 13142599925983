import { Layer } from "../../../types";
import { AssessmentLayerTabUK, AssessmentLayerTabUS } from "./types";

// UK specific layers
import {
  PLANNING_APPLICATION_ASSESSMENT_LAYER,
  PLANNING_APPLICATION_RECENT_SUMMARY_LAYER,
} from "../../../layers/planningApplicationLayer";
import { UK_PROPERTY_INFORMATION_LAYER } from "../../../layers/ukPropertyInformationLayer";
import { BIODIVERSITY_IMPROVEMENT } from "../../../layers/biodiversityImprovementAreasLayer";
import { NEIGHBOURHOOD_PLAN_LAYER } from "../../../layers/neighbourhoodPlanLayer";
import {
  SIGNIFICANT_CONSTRAINTS_FLOODING,
  SIGNIFICANT_CONSTRAINTS_WATER_AND_NUTRIENT_NEUTRALITY,
  SIGNIFICANT_CONSTRAINTS_POLICIES,
  PROTECTED_LAND_SUMMARY_LAYER,
  SIGNIFICANT_CONSTRAINTS_ARTICLE_4,
} from "../../../layers/significantConstraintsLayer";
import {
  LISTED_BUILDINGS_LAYER_CONSIDERATIONS_LIST,
  LISTED_BUILDINGS_SUMMARY_LAYER,
} from "../../../layers/listedBuildings";
import { SPATIAL_RISK_MULTIPLIERS } from "../../../layers/spatialRiskMultiplierLayer";
import { POWER_TOWERS_AND_CABLES } from "../../../layers/powerTowersAndCablesLayer";
import { IRREPLACEABLE_HABITATS } from "../../../layers/irreplaceableHabitats";
import { OTHER_PROTECTED_AREAS } from "../../../layers/otherProtectedAreas";
import { IRREPLACEABLE_AREAS } from "../../../layers/irreplaceableAreas";
import {
  TOPOGRAPHY_INSIGHT_SLOPE_SUMMARY,
  TOPOGRAPHY_INSIGHT_ASPECT_SUMMARY,
  TOPOGRAPHY_LAYER_UK,
} from "../../../layers/topographyLayerUk";
import { OWNERSHIP_SCOTLAND } from "../../../layers/ownershipLayerScotland";
import { AGRICULTURAL_LAND } from "../../../layers/agriculturalLandLayer";
import { POWER_SUBSTATIONS } from "../../../layers/powerSubstationsLayer";
import { UTILITIES_LAYER } from "../../../layers/utilitiesLayer";
import { DEMOGRAPHICS_SUMMARY_UK, DEMOGRAPHICS_UK } from "../../../layers/demographicsLayerUK";
import { ENERGY_PROJECTS } from "../../../layers/energyProjectsLayer";
import { LAND_HUB_LAYER } from "../../../layers/landHubLayer";
import { OTHER_HABITATS } from "../../../layers/otherHabitats";
import { SUMMARY_LAYER } from "../../../layers/summaryLayer";
import { UK_AMENITIES } from "../../../layers/ukAmenitiesLayer";
import { REGEN_ZONES } from "../../../layers/regenZonesLayer";
import { ALLOCATIONS } from "../../../layers/allocationsLayer";
import {
  GREEN_GREY_SUMMARY,
  GREY_BELT,
} from "../../../layers/greybeltAndDeclassifiedGreenbeltLayer";
import {
  LPA_LAYER,
  LPA_LAYER_PIF_SUMMARY,
  LPA_LAYER_APPROVAL_RATE_SUMMARY,
} from "../../../layers/lpaLayer";
import { SHLAA } from "../../../layers/shlaaLayer";

// US specific layers
import {
  US_URBAN_SERVICE_AREA,
  US_UTILITY_STATUS,
  US_WATER_RESOURCES,
} from "../../../layers/usUtilitiesLayer";
import { US_SITE_PLAN_APPLICATIONS } from "../../../layers/usSitePlanApplicationsLayer";
import { ENVIRONMENTAL_PERMITS } from "../../../layers/environmentalPermitsLayer";
import { REZONING_APPLICATIONS } from "../../../layers/rezoningApplicationsLayer";
import { OPPORTUNITY_ZONES } from "../../../layers/opportunityZonesLayer";
import { SOILS_AND_GEOLOGY } from "../../../layers/soilsAndGeologyLayer";
import { ISOCHRONE_LAYER } from "../../../layers/isochroneLayer";
import { DEMOGRAPHICS_SUMMARY_US, DEMOGRAPHICS_US } from "../../../layers/demographicsLayerUS";
import { US_AMENITIES } from "../../../layers/usAmenitiesLayer";
import { HAZARDS_FLOODING, HAZARDS_WETLANDS } from "../../../layers/hazardsLayer";
import { PERMITS } from "../../../layers/permitsLayer";
import { ZONING, ZONING_FLUM_SUMMARY } from "../../../layers/zoningLayer";
import { FLUM } from "../../../layers/flumLayer";

// Shared layers
import {
  COMPARABLES_LAYER,
  COMPARABLES_LAYER_SUMMARY_UK,
  COMPARABLES_LAYER_SUMMARY_US,
  LOCAL_MARKET_VALUE_LAYER_SUMMARY_UK,
} from "../../../layers/comparablesLayer";
import {
  OWNERSHIP_AREA_PERIMETER_SUMMARY_LAYER,
  OWNERSHIP_LAYER,
} from "../../../layers/ownershipLayer";
import { SITES_LAYER } from "../../../layers/sitesLayer";
import { MINING_AND_GEOLOGY } from "../../../layers/miningAndGeologyLayer";

/**
 * Note: These tab definitions are used to group layers in the assessment panel
 * & also the site report.
 */
export const UK_ASSESSMENT_LAYER_BY_TAB: Record<AssessmentLayerTabUK, Layer[]> = {
  [AssessmentLayerTabUK.DETAILS]: [
    { ...OWNERSHIP_LAYER, cardExpanded: true },
    { ...OWNERSHIP_SCOTLAND, cardExpanded: true },
    { ...OWNERSHIP_AREA_PERIMETER_SUMMARY_LAYER, cardExpanded: false, excludeFromReport: true },
    { ...UK_PROPERTY_INFORMATION_LAYER, cardExpanded: true },
    { ...TOPOGRAPHY_LAYER_UK, cardExpanded: true },
    { ...SUMMARY_LAYER, cardExpanded: true },
    { ...SITES_LAYER, cardExpanded: true },
  ],
  [AssessmentLayerTabUK.PLANNING_HISTORY]: [
    { ...PLANNING_APPLICATION_RECENT_SUMMARY_LAYER, cardExpanded: true },
    { ...PLANNING_APPLICATION_ASSESSMENT_LAYER, cardExpanded: true },
  ],
  [AssessmentLayerTabUK.POLICIES]: [
    { ...LPA_LAYER_APPROVAL_RATE_SUMMARY, cardExpanded: true },
    { ...LPA_LAYER_PIF_SUMMARY, cardExpanded: true },
    { ...LPA_LAYER, cardExpanded: true },
    { ...NEIGHBOURHOOD_PLAN_LAYER, cardExpanded: true },
    { ...GREEN_GREY_SUMMARY, cardExpanded: true },
    // Note: Due to the amount of data, the `SIGNIFICANT_CONSTRAINTS_POLICIES`
    // layer has been excluded from the site report so it can be broken down
    // further.
    { ...SIGNIFICANT_CONSTRAINTS_POLICIES, cardExpanded: true, excludeFromReport: true },
    { ...SIGNIFICANT_CONSTRAINTS_ARTICLE_4, cardExpanded: true },
    { ...SHLAA, cardExpanded: true },
    { ...ALLOCATIONS, cardExpanded: true },
    { ...REGEN_ZONES, cardExpanded: true },
    { ...LAND_HUB_LAYER, cardExpanded: true },
    { ...GREY_BELT, cardExpanded: true },
    { ...LISTED_BUILDINGS_LAYER_CONSIDERATIONS_LIST, cardExpanded: true, excludeFromReport: true },
    { ...LISTED_BUILDINGS_SUMMARY_LAYER, cardExpanded: true },
    { ...PROTECTED_LAND_SUMMARY_LAYER, cardExpanded: true },
  ],
  [AssessmentLayerTabUK.ENVIRONMENTAL]: [
    { ...SIGNIFICANT_CONSTRAINTS_FLOODING, cardExpanded: true, excludeFromReport: true },
    {
      ...SIGNIFICANT_CONSTRAINTS_WATER_AND_NUTRIENT_NEUTRALITY,
      cardExpanded: true,
      excludeFromReport: true,
    },
    { ...AGRICULTURAL_LAND, cardExpanded: true },
    { ...IRREPLACEABLE_HABITATS, cardExpanded: true },
    { ...OTHER_HABITATS, cardExpanded: true },
    { ...IRREPLACEABLE_AREAS, cardExpanded: true },
    { ...OTHER_PROTECTED_AREAS, cardExpanded: true },
    { ...BIODIVERSITY_IMPROVEMENT, cardExpanded: true },
    { ...SPATIAL_RISK_MULTIPLIERS, cardExpanded: true },
    { ...MINING_AND_GEOLOGY, cardExpanded: true, excludeFromReport: true },
  ],
  [AssessmentLayerTabUK.COMPS_AND_LOCAL]: [
    { ...COMPARABLES_LAYER, cardExpanded: true },
    { ...COMPARABLES_LAYER_SUMMARY_UK, cardExpanded: true },
    { ...DEMOGRAPHICS_SUMMARY_UK, cardExpanded: true },
    { ...DEMOGRAPHICS_UK, cardExpanded: true },
    // Note: This layer has been excluded from the report due to an issue
    // rendering required icons. I believe the issue is related to a race
    // condition where the deck.gl layer is released before the icons are loaded
    // & rendered.
    { ...UK_AMENITIES, cardExpanded: true, excludeFromReport: true },
    { ...LOCAL_MARKET_VALUE_LAYER_SUMMARY_UK, cardExpanded: false, excludeFromReport: true },
  ],
  [AssessmentLayerTabUK.POWER_AND_UTILITIES]: [
    { ...POWER_SUBSTATIONS, cardExpanded: true },
    { ...POWER_TOWERS_AND_CABLES, cardExpanded: true },
    { ...ENERGY_PROJECTS, cardExpanded: true },
    { ...UTILITIES_LAYER, cardExpanded: true },
  ],
  [AssessmentLayerTabUK.TOPOGRAPHY]: [
    TOPOGRAPHY_INSIGHT_SLOPE_SUMMARY,
    TOPOGRAPHY_INSIGHT_ASPECT_SUMMARY,
  ],
};

/**
 * Note: These tab definitions are used to group layers in the assessment panel
 * & also the site report.
 */
export const US_ASSESSMENT_LAYER_BY_TAB: Record<AssessmentLayerTabUS, Layer[]> = {
  [AssessmentLayerTabUS.DETAILS]: [
    { ...OWNERSHIP_LAYER, cardExpanded: true },
    { ...SITES_LAYER, cardExpanded: true },
  ],
  [AssessmentLayerTabUS.PERMITS]: [
    { ...PERMITS, cardExpanded: true },
    { ...ENVIRONMENTAL_PERMITS, cardExpanded: true },
    { ...REZONING_APPLICATIONS, cardExpanded: true },
    { ...US_SITE_PLAN_APPLICATIONS, cardExpanded: true },
  ],
  [AssessmentLayerTabUS.POLICIES]: [
    { ...ZONING_FLUM_SUMMARY, cardExpanded: true },
    { ...ZONING, cardExpanded: true },
    { ...FLUM, cardExpanded: true },
    { ...OPPORTUNITY_ZONES, cardExpanded: true },
  ],
  [AssessmentLayerTabUS.ENVIRONMENTAL]: [
    { ...HAZARDS_FLOODING, cardExpanded: true },
    { ...HAZARDS_WETLANDS, cardExpanded: true },
    { ...SOILS_AND_GEOLOGY, cardExpanded: true },
  ],
  [AssessmentLayerTabUS.COMPS_AND_LOCAL]: [
    { ...DEMOGRAPHICS_SUMMARY_US, cardExpanded: true, excludeFromReport: true },
    { ...COMPARABLES_LAYER, cardExpanded: true },
    { ...COMPARABLES_LAYER_SUMMARY_US, cardExpanded: true },
    { ...DEMOGRAPHICS_US, cardExpanded: true },
    { ...US_AMENITIES, cardExpanded: true },
    { ...ISOCHRONE_LAYER, cardExpanded: true },
  ],
  [AssessmentLayerTabUS.UTILITIES]: [
    { ...US_URBAN_SERVICE_AREA, cardExpanded: true },
    { ...US_UTILITY_STATUS, cardExpanded: true },
    { ...US_WATER_RESOURCES, cardExpanded: true },
  ],
};
