import { gql, useQuery } from "@apollo/client";
import { LandTechEndpoints, routedClient } from "react-migration/lib/persistence/apollo";
import { SingleDesignation } from "react-migration/domains/constraints/typings/applicationTypes/SingleDesignation";

import { SOURCE_STATUSES } from "react-migration/domains/constraints/constants";
type UseDesignationByCategoryArgs = Partial<{
  requiredAttributes: { key: string; value: string }[];
  keys: string[];
  skip: boolean;
  dataStatuses?: "live" | "expired" | "data_entered" | "incomplete" | "archived";
  sourceStatuses: (typeof SOURCE_STATUSES)[number]["id"][];
}>;

export function useDesignationsByCategory(category: string, opts?: UseDesignationByCategoryArgs) {
  const { data, ...rest } = useQuery<{ designationsByCategory: SingleDesignation[] }>(
    gql`
      query DesignationsByCategory(
        $categoryId: String
        $keys: [String]
        $requiredAttributes: [AttributeSearch]
        $dataStatuses: [String]
        $sourceStatuses: [String]
      ) {
        designationsByCategory(categoryId: $categoryId, requiredAttributes: $requiredAttributes) {
          id
          display_name
          sub_category_id
          designation_attributes(keys: $keys) {
            id
            key
            value
          }
        }
      }
    `,
    {
      variables: {
        categoryId: category,
        ...opts,
      },
      client: routedClient,
      context: {
        endpoint: LandTechEndpoints.Constraints,
      },
      skip: opts?.skip,
    }
  );

  return { designations: data?.designationsByCategory, ...rest };
}
