import Feature from "src/js/stores/user/Feature";
import { ConstraintsCategory } from "../../Constraints/types";
import {
  ConstraintsLayer,
  ConstraintsLayerType,
  ProtectedLandSummaryLayerType,
} from "../layer_types/ConstraintsLayerType";
import { FloodingLayerType } from "../layer_types/FloodingLayerType";
import { Layer } from "../types";

export const SIGNIFICANT_CONSTRAINTS: Layer = {
  id: ConstraintsCategory.POLICY,
  layerType: ConstraintsLayerType,
  title: "Significant Constraints",
  description: "Could limit the type of development that can be done, or restrict use",
  toggleable: true,
  requiredFeatureAccess: [Feature.planningPolicyLayer],
  layerConfig: {
    rootCategory: ConstraintsCategory.POLICY,
    layerRoots: [
      "protected_areas_outer",
      "heritage_land_listed_buildings_outer",
      "flood_zones_outer",
      "conservation_areas_outer",
      "safe_guarding_outer",
      "employment_area_outer",
      "settlement_boundaries_outer",
      "tree_preservation_orders",
      "protected_views_outer",
      "green_belt_outer",
      "nutrient_neutrality",
      "water_neutrality",
      "national_landscape",
      "open_land_and_green_space",
      "very_low_risk_of_flooding_from_rivers_and_seas",
      "low_risk_of_flooding_from_rivers_and_seas",
      "medium_risk_of_flooding_from_rivers_and_seas",
      "high_risk_of_flooding_from_rivers_and_seas",
      "rights_of_way",
    ],
    categorySchema: [
      { key: "protected_areas_outer" },
      { key: "national_landscape" },
      { key: "heritage_land_listed_buildings_outer" },
      { key: "flood_zones_outer" },
      { key: "very_low_risk_of_flooding_from_rivers_and_seas" },
      { key: "low_risk_of_flooding_from_rivers_and_seas" },
      { key: "medium_risk_of_flooding_from_rivers_and_seas" },
      { key: "high_risk_of_flooding_from_rivers_and_seas" },
      { key: "conservation_areas_outer" },
      { key: "employment_area_outer" },
      { key: "settlement_boundaries_outer" },
      { key: "green_belt_outer" },
      { key: "nutrient_neutrality" },
      { key: "water_neutrality_offsetting_allowed" },
      { key: "water_neutrality_no_offsetting" },
      { key: "protected_views_outer" },
      { key: "safe_guarding_outer" },
      { key: "metropolitan_open_land_outer" },
      { key: "open_space_outer" },
      { key: "rights_of_way" },
      { key: "tree_preservation_orders" },
    ],
    statusSchema: [{ key: "adopted" }, { key: "draft", defaultValue: false }],
  },
};

export const SIGNIFICANT_CONSTRAINTS_POLICIES: Layer = {
  id: ConstraintsCategory.POLICY,
  layerType: ConstraintsLayerType,
  title: "Policy Constraints",
  description: "Could limit the type of development that can be done, or restrict use",
  toggleable: true,
  requiredFeatureAccess: [Feature.planningPolicyLayer],
  layerConfig: {
    rootCategory: ConstraintsCategory.POLICY,
    layerRoots: [
      "protected_areas_outer",
      "national_landscape",
      "heritage_land_listed_buildings_outer",
      "conservation_areas_outer",
      "employment_area_outer",
      "settlement_boundaries_outer",
      "green_belt_outer",
      "protected_views_outer",
      "safe_guarding_outer",
      "open_land_and_green_space",
      "rights_of_way",
      "tree_preservation_orders",
    ],
    categorySchema: [
      { key: "protected_areas_outer" },
      { key: "national_landscape" },
      { key: "heritage_land_listed_buildings_outer" },
      { key: "conservation_areas_outer" },
      { key: "employment_area_outer" },
      { key: "settlement_boundaries_outer" },
      { key: "green_belt_outer" },
      { key: "protected_views_outer" },
      { key: "safe_guarding_outer" },
      { key: "metropolitan_open_land_outer" },
      { key: "open_space_outer" },
      { key: "rights_of_way" },
      { key: "tree_preservation_orders" },
    ],
    statusSchema: [{ key: "adopted" }, { key: "draft", defaultValue: false }],
  },
};

export const SIGNIFICANT_CONSTRAINTS_ARTICLE_4: Layer = {
  id: ConstraintsCategory.POLICY + "-article-4",
  layerType: ConstraintsLayerType,
  title: "Article 4 Directive",
  description: "Could limit the type of development that can be done, or restrict use",
  toggleable: true,
  requiredFeatureAccess: [Feature.planningPolicyLayer],
  requiredBetaAccess: [Feature.article4Beta],
  label: { color: "BLUE", translationKey: "map.explore.label.beta" },
  layerConfig: {
    rootCategory: ConstraintsCategory.ARTICLE_4,
    categorySchema: [
      { key: "article_4_ma" },
      { key: "article_4_hmo" },
      { key: "article_4_other" },
      { key: "article_4_unknown" },
    ],
    statusSchema: [{ key: "adopted" }, { key: "draft", defaultValue: false }],
  },
};

export const SIGNIFICANT_CONSTRAINTS_FLOODING: Layer = {
  id: `${ConstraintsCategory.POLICY}--flooding`,
  layerType: FloodingLayerType,
  title: "Flooding",
  description: "Could limit the type of development that can be done, or restrict use",
  toggleable: true,
  requiredFeatureAccess: [Feature.planningPolicyLayer],
  layerConfig: {
    rootCategory: ConstraintsCategory.POLICY,
    layerRoots: ["flood_zones_outer", "risk_of_flooding_from_rivers_and_seas"],
    categorySchema: [
      { key: "flood_zones_outer" },
      { key: "very_low_risk_of_flooding_from_rivers_and_seas" },
      { key: "low_risk_of_flooding_from_rivers_and_seas" },
      { key: "medium_risk_of_flooding_from_rivers_and_seas" },
      { key: "high_risk_of_flooding_from_rivers_and_seas" },
    ],
    statusSchema: [{ key: "adopted" }, { key: "draft", defaultValue: false }],
  },
};

export const SIGNIFICANT_CONSTRAINTS_WATER_AND_NUTRIENT_NEUTRALITY: Layer = {
  id: `${ConstraintsCategory.POLICY}--water-and-nutrient-neutrality`,
  layerType: ConstraintsLayerType,
  title: "Water and Nutrient Neutrality",
  description: "Could limit the type of development that can be done, or restrict use",
  toggleable: true,
  requiredFeatureAccess: [Feature.planningPolicyLayer],
  layerConfig: {
    rootCategory: ConstraintsCategory.POLICY,
    layerRoots: ["nutrient_neutrality", "water_neutrality"],
    categorySchema: [
      { key: "water_neutrality_offsetting_allowed" },
      { key: "water_neutrality_no_offsetting" },
      { key: "nutrient_neutrality" },
    ],
    statusSchema: [{ key: "adopted" }, { key: "draft", defaultValue: false }],
  },
};

function createConstraintsLayer(
  layer: Pick<ConstraintsLayer, "title" | "description" | "layerConfig" | "id">
): ConstraintsLayer {
  return {
    layerType: ConstraintsLayerType,
    toggleable: true,
    requiredFeatureAccess: [Feature.planningPolicyLayer],
    ...layer,
  };
}

/** Provide user with better breakdown of policy data in site report. */
export const UK_POLICY_SITE_REPORT_LAYERS: Layer[] = [
  createConstraintsLayer({
    id: "protected_areas",
    title: "Protected Areas",
    description: "Protected Areas",
    layerConfig: {
      rootCategory: ConstraintsCategory.POLICY,
      categorySchema: [{ key: "protected_areas_outer" }, { key: "national_landscape" }],
      statusSchema: [{ key: "adopted" }, { key: "draft", defaultValue: false }],
    },
  }),
  createConstraintsLayer({
    id: "heritage_and_conservation",
    title: "Heritage and Conservation",
    description: "Heritage and Conservation",
    layerConfig: {
      rootCategory: ConstraintsCategory.POLICY,
      categorySchema: [
        { key: "heritage_land_listed_buildings_outer" },
        { key: "conservation_areas_outer" },
        { key: "protected_views_outer" },
      ],
      statusSchema: [{ key: "adopted" }, { key: "draft", defaultValue: false }],
    },
  }),
  createConstraintsLayer({
    id: "green_belt_open_space",
    title: "Green Belt, Open Space, and MOL",
    description: "Green Belt, Open Space, and MOL",
    layerConfig: {
      rootCategory: ConstraintsCategory.POLICY,
      categorySchema: [
        { key: "green_belt_outer" },
        { key: "metropolitan_open_land_outer" },
        { key: "open_land_and_green_space" },
      ],
      statusSchema: [{ key: "adopted" }, { key: "draft", defaultValue: false }],
    },
  }),
  createConstraintsLayer({
    id: "nutrient_and_water_neutrality",
    title: "Nutrient and Water Neutrality",
    description: "Nutrient and Water Neutrality",
    layerConfig: {
      rootCategory: ConstraintsCategory.POLICY,
      categorySchema: [
        { key: "nutrient_neutrality" },
        { key: "water_neutrality" },
        { key: "water_neutrality_offsetting_allowed" },
        { key: "water_neutrality_no_offsetting" },
      ],
      statusSchema: [{ key: "adopted" }, { key: "draft", defaultValue: false }],
    },
  }),
  createConstraintsLayer({
    id: "employment_land_use",
    title: "Employment Land Use",
    description: "Employment Land Use",
    layerConfig: {
      rootCategory: ConstraintsCategory.POLICY,
      categorySchema: [{ key: "employment_area_outer" }],
      statusSchema: [{ key: "adopted" }, { key: "draft", defaultValue: false }],
    },
  }),
  createConstraintsLayer({
    id: "settlement_boundaries",
    title: "Settlement Boundaries",
    description: "Settlement Boundaries",
    layerConfig: {
      rootCategory: ConstraintsCategory.POLICY,
      categorySchema: [{ key: "settlement_boundaries_outer" }],
      statusSchema: [{ key: "adopted" }, { key: "draft", defaultValue: false }],
    },
  }),
  createConstraintsLayer({
    id: "safeguarding",
    title: "Safeguarding",
    description: "Safeguarding",
    layerConfig: {
      rootCategory: ConstraintsCategory.POLICY,
      categorySchema: [{ key: "safe_guarding_outer" }],
      statusSchema: [{ key: "adopted" }, { key: "draft", defaultValue: false }],
    },
  }),
  createConstraintsLayer({
    id: "tree_preservation_orders",
    title: "Tree Preservation Orders",
    description: "Tree Preservation Orders",
    layerConfig: {
      rootCategory: ConstraintsCategory.POLICY,
      categorySchema: [{ key: "tree_preservation_orders" }],
      statusSchema: [{ key: "adopted" }, { key: "draft", defaultValue: false }],
    },
  }),
  createConstraintsLayer({
    id: "public_access",
    title: "Public Access",
    description: "Public Access",
    layerConfig: {
      rootCategory: ConstraintsCategory.POLICY,
      categorySchema: [{ key: "rights_of_way" }],
      statusSchema: [{ key: "adopted" }, { key: "draft", defaultValue: false }],
    },
  }),
];

/** Provide user with better breakdown of environmental data in site report. */
export const UK_ENVIRONMENTAL_SITE_REPORT_LAYERS: Layer[] = [
  createConstraintsLayer({
    id: "flood_zones",
    title: "Flood Zones",
    description: "Flood Zones",
    layerConfig: {
      rootCategory: ConstraintsCategory.POLICY,
      categorySchema: [{ key: "flood_zones_outer" }],
      statusSchema: [{ key: "adopted" }, { key: "draft", defaultValue: false }],
    },
  }),
  createConstraintsLayer({
    id: "flood_risk",
    title: "Flood Risk",
    description: "Flood Risk",
    layerConfig: {
      rootCategory: ConstraintsCategory.POLICY,
      categorySchema: [
        { key: "very_low_risk_of_flooding_from_rivers_and_seas" },
        { key: "low_risk_of_flooding_from_rivers_and_seas" },
        { key: "medium_risk_of_flooding_from_rivers_and_seas" },
        { key: "high_risk_of_flooding_from_rivers_and_seas" },
      ],
      statusSchema: [{ key: "adopted" }, { key: "draft", defaultValue: false }],
    },
  }),
];

export const PROTECTED_LAND_SUMMARY_LAYER: Layer = {
  id: "PROTECTED_LAND_SUMMARY",
  layerType: ProtectedLandSummaryLayerType,
  title: "Protected land",
  description: "Protected land",
  // TODO - currently piggy backing off property information feature as it's
  // availalbe to starter and pro. Should create a new feature that is specific
  // to this tier of insight summaries.
  requiredFeatureAccess: [Feature.propertyInformationLayer],
  requiredBetaAccess: [Feature.insightsSummaryBeta],
  toggleable: true,
  hideByDefault: true,
  excludeFromReport: true,
  hideMapLayer: true,
};
