import classNames from "classnames";

export type DropdownMenuSeparatorProps = {
  "data-testid"?: string;
  className?: string;
};

export const DropdownMenuSeparator = ({
  className: additionalClasses,
  "data-testid": dataTestId,
}: DropdownMenuSeparatorProps) => {
  return (
    <div
      data-testid={dataTestId ?? "dropdown-menu-separator"}
      className={classNames(
        "atlas-h-px",
        "atlas-bg-border-base-divider",
        "atlas-w-auto",
        "atlas-my-2",
        additionalClasses
      )}
    />
  );
};
