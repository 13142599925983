import { ISourcingToolSearchFilter } from "react-migration/domains/search/typings/SourcingToolSpecificFilterTypes";
import { SearchType } from "react-migration/domains/search/constants/searchTypes";
import Feature from "src/js/stores/user/Feature";

export const Article4Filter: ISourcingToolSearchFilter = {
  id: "article-four-id",
  type: "checkbox",
  field: "sidebar.react.search_sidebar.article_four",
  must: "MATCH_NONE",
  values: [],
  mustOptions: ["MATCH_ANY", "MATCH_NONE"],
  updateFilterDialogIsVisible: false,
  searchTypes: [SearchType.SOURCING_TOOL_UK],
  include: [Feature.article4Beta],
};
